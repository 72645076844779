import { Box, Chip, Menu, MenuItem } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { NumberOfFiltersForColumnIcon } from "@components/common/faro-table/faro-table-filter/number-of-filters-for-column-icon";
import {
  BaseFilterMapId,
  FilterId,
} from "@components/common/faro-table/faro-table-filter/faro-table-filter-types";
import { FilterChipDropdownItem } from "@components/common/faro-table/faro-table-filter/filter-chip-dropdown-item";
import { useMemo, useState } from "react";
import { useFilterQueryParams } from "@hooks/table-filters/use-filter-query-params";
import { FilterChipSearch } from "@components/common/faro-table/faro-table-filter/filter-chip-search";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import {
  FilterTableEvents,
  TableType,
} from "@utils/track-event/track-event-list";
import { HeaderForTableType } from "@components/common/faro-table/faro-table-types";

interface Props {
  /** The column in which filtering will apply to */
  filteredColumn: HeaderForTableType<TableType>;

  /** The label of the chip to show in filter bar */
  label: string;

  /** The items to show in the dropdown */
  items: BaseFilterMapId;

  /** Whether to show a searchbox above options or not */
  shouldShowSearchBox: boolean;

  /** Whether to show a checkbox next to each item or not */
  shouldShowCheckbox: boolean;

  /** Whether to show the check icon on the active items on right */
  shouldShowCheckIconOnRight?: boolean;

  /** The table in which the filter is applied to */
  filterTableType: FilterTableEvents;

  /** The size of the dropdown */
  size?: "small" | "large";
}

/** A chip to show for grouping set of filters. The children is the content shown after clicking on a chip */
export function DropdownFilterChip({
  filteredColumn,
  label,
  items,
  shouldShowSearchBox,
  shouldShowCheckbox,
  shouldShowCheckIconOnRight = false,
  filterTableType,
  size = "small",
}: Props): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchText, setSearchText] = useState<string>("");
  const { trackEvent } = useTrackEvent();

  /** Filter items based on search text */
  const filterItems = useMemo(() => {
    return Object.values(items).filter((item) =>
      item.label.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [items, searchText]);

  const { getFilterIdsForColumn, updateFilterItem } = useFilterQueryParams();
  const activeFilterIds = getFilterIdsForColumn(filteredColumn);

  function onOptionClicked(filterId: FilterId): void {
    trackEvent({
      name: filterTableType,
      props: { filterBy: filteredColumn },
    });

    updateFilterItem({
      filteredColumn,
      filterId,
      shouldReplaceFilterInColumn: !shouldShowCheckbox,
    });
    setSearchText("");
  }

  return (
    <Box component="div">
      <Chip
        onClick={(event) => setAnchorEl(event.currentTarget)}
        label={label}
        variant="outlined"
        sx={{
          backgroundColor: sphereColors.pureWhite,
          color: sphereColors.gray800,
          border: `2px solid ${
            anchorEl ? sphereColors.blue500 : sphereColors.gray300
          }`,
          fontSize: "12px",
          cursor: "pointer",
          flexDirection: "row-reverse",
        }}
        size="small"
        icon={<NumberOfFiltersForColumnIcon filteredColumn={filteredColumn} />}
      />
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        slotProps={{
          paper: {
            sx: {
              width: size === "small" ? "230px" : "350px",
              maxHeight: "330px",
              overflowY: "auto",
            },
          },
        }}
        sx={{
          "& .MuiMenuItem-root": {
            fontSize: "14px",
            fontWeight: 400,
            px: "8px",
            py: "2px",
          },
        }}
      >
        {shouldShowSearchBox && (
          <FilterChipSearch
            searchText={searchText}
            setSearchText={setSearchText}
            onEscape={() => {
              setAnchorEl(null);
              setSearchText("");
            }}
          />
        )}
        {filterItems.length === 0 ? (
          <MenuItem
            sx={{
              lineHeight: "36px",
              ml: "8px",
              pointerEvents: "none",
              textWrap: "wrap",
            }}
          >
            No {label.toLowerCase()} found!
          </MenuItem>
        ) : (
          filterItems.map((item) => (
            <FilterChipDropdownItem
              key={item.id}
              filterItem={item}
              isItemSelected={activeFilterIds.includes(item.id)}
              onItemClicked={onOptionClicked}
              shouldShowCheckbox={shouldShowCheckbox}
              shouldShowCheckIconOnRight={shouldShowCheckIconOnRight}
              size={size}
            />
          ))
        )}
      </Menu>
    </Box>
  );
}
