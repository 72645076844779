import { useEffect } from "react";
import { getUlmUrl } from "@context-providers/auth/use-auth-ulm-utils";
import {
  isUlmRedirectMessage,
  UlmRedirectMessage,
} from "@faro-lotv/gate-keepers";

/**
 * Handles an instance of the unified login mask.
 *
 * @returns The URL to the login page of the ULM
 */
export function useAuthUlm(): string {
  /**
   * Add event listener for window messages coming from the ULM.
   * Redirect to the received URL if the message is of type UlmRedirectMessage.
   */
  useEffect(() => {
    function onWindowMessage(message: MessageEvent<UlmRedirectMessage>): void {
      if (isUlmRedirectMessage(message.data)) {
        window.location.href = message.data.redirectUrl;
      }
    }

    window.addEventListener("message", onWindowMessage);

    return () => window.removeEventListener("message", onWindowMessage);
  }, []);

  return getUlmUrl();
}
