// CssBaseline needs to be imported before all other styles to avoid errors
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { IReactChildrenOnly } from "@custom-types/types";
import { SdbCompanyLoader } from "@loaders/sdb-company-loader";
import { Sidebar } from "@components/sidebar/sidebar";
import { DialogProvider } from "@components/common/dialog/dialog-provider";
import { runtimeConfig } from "@src/runtime-config";
import { Localization } from "@utils/localization";
import { mainTheme } from "@styles/themes";
import { useErrorHandler } from "@hooks/use-error-handler";
import { TrackEventInitializerLoader } from "@loaders/track-event-initializer-loader";
import { QueryParamsLoader } from "@loaders/query-params-loader";
import { SplashScreenManager } from "@components/splash-screen/splash-screen-manager";
import { KeyBindingsLoader } from "@loaders/key-bindings-loader";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterLuxon } from "@mui/x-date-pickers-pro/AdapterLuxon";
import { useAppSelector } from "@store/store-helper";
import { sidebarSelector } from "@store/ui/ui-selector";
import { IntercomLoader } from "@loaders/intercom-loader";
import { FileUploadContextProvider } from "@context-providers/file-upload/file-uploads-context";
import { LicenseInfo } from "@mui/x-license";
import { useHandleWindowClose } from "@hooks/use-handle-window-close";

/**
 * All the context providers should be added here to make them available across the whole application.
 *
 * @returns all the react context providers and it's initialization.
 */
function AllContextProviders({ children }: IReactChildrenOnly): JSX.Element {
  return (
    // Make sure to initialize the tracking event before other parts of the app,
    // so the tracking is available from the start and no error is shown for not able to track events.
    <TrackEventInitializerLoader>
      <CssBaseline />
      <ThemeProvider theme={mainTheme}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DialogProvider>
            <SdbCompanyLoader>
              <QueryParamsLoader>
                <FileUploadContextProvider>
                  <KeyBindingsLoader>
                    <IntercomLoader>{children}</IntercomLoader>
                  </KeyBindingsLoader>
                </FileUploadContextProvider>
              </QueryParamsLoader>
            </SdbCompanyLoader>
          </DialogProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </TrackEventInitializerLoader>
  );
}

/**
 * Entry point deciding on the structure of the app
 */
export function App(): JSX.Element {
  if (runtimeConfig.features.muiProKey) {
    LicenseInfo.setLicenseKey(runtimeConfig.features.muiProKey);
  }

  const sidebar = useAppSelector(sidebarSelector);

  /** Initialize the Localization feature with the key provided in runtimeConfig */
  if (runtimeConfig.features.localizeKey) {
    Localization.init(runtimeConfig.features.localizeKey);
  }

  useErrorHandler();

  useHandleWindowClose();

  return (
    <AllContextProviders>
      <div className="App">
        <Box component="div" sx={{ display: "flex", height: "100vh" }}>
          {sidebar.isVisible && <Sidebar />}
          {/* <Outlet> is a placeholder for the active child route defined in the routes.ts */}
          <Outlet />
          <SplashScreenManager />
        </Box>
      </div>
    </AllContextProviders>
  );
}
