import { Box } from "@mui/material";
import { useFilterQueryParams } from "@hooks/table-filters/use-filter-query-params";
import { useMemo } from "react";
import { sphereColors } from "@styles/common-colors";
import { HeaderForTableType } from "@components/common/faro-table/faro-table-types";
import { TableType } from "@utils/track-event/track-event-list";

interface Props {
  /** The column in which filtering will apply to */
  filteredColumn: HeaderForTableType<TableType>;
}

/** Icon to show number of filters next to a chip */
export function NumberOfFiltersForColumnIcon({
  filteredColumn,
}: Props): JSX.Element | null {
  const { getFilterIdsForColumn } = useFilterQueryParams();
  const numberOfActiveFilters = useMemo(
    () => getFilterIdsForColumn(filteredColumn).length,
    [getFilterIdsForColumn, filteredColumn]
  );

  if (numberOfActiveFilters === 0) {
    return null;
  }

  return (
    <Box
      component="div"
      sx={{
        width: "18px",
        height: "18px",
        backgroundColor: sphereColors.blue400,
        color: sphereColors.pureWhite,
        textAlign: "center",
        borderRadius: "50%",
        marginRight: "6px",
      }}
    >
      <var>{numberOfActiveFilters}</var>
    </Box>
  );
}
