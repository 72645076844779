import { useFilterQueryParams } from "@hooks/table-filters/use-filter-query-params";
import { EmptyPage } from "@components/common/empty-page/empty-page";
import { FaroTextButton } from "@components/common/faro-text-button";
import BrowseDuoColorIcon from "@assets/icons/browse-duo-color.svg?react";
import { useMemo } from "react";
import { useAppSelector } from "@store/store-helper";
import { tableTypeSelector } from "@store/table/table-selector";
import { TableType } from "@utils/track-event/track-event-list";

// TODO: Should be extended to show no data exist as well: https://faro01.atlassian.net/browse/ST-2197
/**
 * This component is shown when table is loaded and no data is provided.
 * This can be because no data exist, or because filters are applied and no data matches the filters.
 * Or even no data matches the search
 */
export function FaroTableEmptyPage(): JSX.Element | null {
  const tableType = useAppSelector(tableTypeSelector);
  const {
    clearAllFilters,
    quickFilter,
    isAnyFilterApplied,
    updateQuickFilter,
  } = useFilterQueryParams();

  const clearButtonText = useMemo(() => {
    if (quickFilter && isAnyFilterApplied) {
      return "Clear all";
    }
    if (quickFilter) {
      return "Clear search";
    }
    if (isAnyFilterApplied) {
      return "Clear filters";
    }
  }, [isAnyFilterApplied, quickFilter]);

  /** Text to show in place of the entity */
  const entityPlaceholder = useMemo(() => {
    switch (tableType) {
      case TableType.projectMarkups:
        return "annotations";

      default:
        return "results";
    }
  }, [tableType]);

  if (isAnyFilterApplied || quickFilter) {
    return (
      <EmptyPage
        icon={BrowseDuoColorIcon}
        title={`No matching ${entityPlaceholder} found`}
        subtitle={
          <FaroTextButton
            onClick={() => {
              clearAllFilters();
              updateQuickFilter("");
            }}
            sx={{ fontSize: "16px" }}
          >
            {clearButtonText}
          </FaroTextButton>
        }
      />
    );
  }

  return null;
}
