import { Stack } from "@mui/material";
import { useState } from "react";
import { FilterToolbarQuickSearch } from "@components/common/faro-table/faro-table-filter/filter-toolbar-quick-search";
import { FilterToolbarClearAll } from "@components/common/faro-table/faro-table-filter/filter-toolbar-clear-all";
import { FilterToolbarTitle } from "@components/common/faro-table/faro-table-filter/filter-toolbar-title";

interface Props {
  /** filter chips shown in filter toolbar */
  filterChipComponents: JSX.Element[];
}

/** Toolbar to show for filter chips in small screens */
export function FilterToolbarSmallScreen({
  filterChipComponents,
}: Props): JSX.Element {
  const [shouldShowFilterButtons, setShouldShowFilterButtons] =
    useState<boolean>(false);

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack
          direction="row"
          justifyContent={"space-between"}
          sx={{
            height: "28px",
            alignItems: "center",
            gap: "8px",
            my: "17px",
          }}
        >
          <FilterToolbarTitle
            shouldShowFilterButtons={shouldShowFilterButtons}
            setShouldShowFilterButtons={setShouldShowFilterButtons}
          />
          <FilterToolbarClearAll />
        </Stack>
        <FilterToolbarQuickSearch />
      </Stack>

      {shouldShowFilterButtons && (
        <Stack
          direction="row"
          justifyContent="flex-start"
          gap="2px"
          alignItems="center"
          flexWrap="wrap"
        >
          {filterChipComponents}
        </Stack>
      )}
    </>
  );
}
