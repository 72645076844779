import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { AuthContext } from "@context-providers/auth/auth-context";
import { useLoadingSpinner } from "@context-providers/loading-spinner-provider";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { resetStore } from "@store/store";
import { runtimeConfig } from "@src/runtime-config";
import { FaroDialog } from "@components/common/dialog/faro-dialog";
import {
  AuthBroadcastChannel,
  useAuthBroadcastChannel,
} from "@context-providers/auth/use-auth-broadcast-channel";
import { useAuthUserModule } from "@context-providers/auth/use-auth-user-module";
import { UserModule } from "@context-providers/auth/user-module";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { useAuthUlm } from "@context-providers/auth/use-auth-ulm";
import { Ulm } from "@context-providers/auth/ulm";
import { BrowserUtils } from "@stellar/web-core";
import { useCoreApiClient } from "@api/use-core-api-client";
import { loggedInUserWithStatusSelector } from "@store/user/user-selector";
import { isLoginProviderFaro } from "@context-providers/auth/auth-provider-utils";

/**
 * Component that handles login and logout actions.
 *
 * This component also wraps the application in order to:
 * - Allow access to the app only if the user is logged in (has an active CoreAPI session).
 * - Block access to the app and show the XG login service (ULM) if the user is logged out.
 */
export function AuthProvider({ children }: PropsWithChildren): JSX.Element {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [hasCarriedOutLoginCheck, setHasCarriedOutLoginCheck] =
    useState<boolean>(false);
  // State for the modal dialog informing the user that the authentication session is invalid and needs to login again
  const [isModalDialogOpen, setIsModalDialogOpen] = useState<boolean>(false);
  const [shouldShowUlm, setShouldShowUlm] = useState<boolean>(false);
  const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);
  const [auth0LogoutUrl, setAuthLogout0Url] = useState<string | undefined>(
    undefined
  );

  const { setLoadingSpinner } = useLoadingSpinner();
  const dispatch = useAppDispatch();
  const { handleErrorWithToast } = useErrorContext();
  const coreApiClient = useCoreApiClient();
  const loggedInUser = useAppSelector(loggedInUserWithStatusSelector);

  // Handler for the user module
  const { iFrameUrl } = useAuthUserModule({
    loginCheckCallback: (isUserLoggedIn) => {
      setIsLoggedIn(isUserLoggedIn);
      // After the user module sends the logins status setHasCarriedOutLoginCheck to true
      setHasCarriedOutLoginCheck(true);

      if (!isUserLoggedIn) {
        setLoadingSpinner(false);
        // If the "direct_login" search param is set and it's value is "true":
        // don't show the ULM and let the login workflow continue in the user module.
        const directLogin = BrowserUtils.readSearchParam("direct_login");
        if (directLogin === "true") {
          setShouldShowUlm(false);
        } else {
          setShouldShowUlm(true);
        }
      }
    },

    successfulLogoutCallback: () => successfulLogoutCallback,
  });

  // Handler for the Unified Login Mask
  const ulmUrl = useAuthUlm();

  // Channel that sends messages across browser tabs. Used for sharing the `logout` message.
  const broadcastChannel = useAuthBroadcastChannel({
    logoutCallback: () => setIsModalDialogOpen(true),
  });

  // Start loading spinner when the user module has not checked yet the login status
  useEffect(() => {
    if (hasCarriedOutLoginCheck === false) {
      setLoadingSpinner(true);
    }
  }, [hasCarriedOutLoginCheck, setLoadingSpinner]);

  const requestLogin = useCallback(() => {
    setIsModalDialogOpen(true);
  }, [setIsModalDialogOpen]);

  /**
   * Callback after a successful logout:
   * - Set shouldShowUlm to true and isLoggedIn to false in order to display the ULM
   * - Resets the app store
   * - Broadcasts successful logout message to other browser tabs
   */
  const successfulLogoutCallback = useCallback(() => {
    setShouldShowUlm(true);
    setIsLoggedIn(false);
    resetStore(dispatch);
    if (broadcastChannel) {
      broadcastChannel.postMessage(AuthBroadcastChannel.messages.logoutMessage);
    }
  }, [broadcastChannel, dispatch]);

  /**
   * Logs out the user from the application.
   *
   * For users with login method FARO we also need to invalidate the Auth0 session.
   * There is a non-visible iframe where the Auth0 logout will be silently carried out.
   *
   * After a successful logout a callback function takes care of showing the ULM,
   * clearing the app store and state, and finally it broadcasts a logout message to any other
   * SDB tab open so the other tabs can show a dialog that requests the user to login again.
   */
  const logout = useCallback(async () => {
    setIsLoggingOut(true);
    setLoadingSpinner(true);

    try {
      await coreApiClient.V3.SDB.logoutUser();

      if (isLoginProviderFaro(loggedInUser)) {
        const domain = runtimeConfig.features.auth0.domain;
        const clientId = runtimeConfig.features.auth0.clientId;
        const returnTo = encodeURIComponent(
          runtimeConfig.urls.sphereEntryPageUrl
        );
        const logoutUrl = `https://${domain}/v2/logout?client_id=${clientId}&returnTo=${returnTo}`;
        setAuthLogout0Url(logoutUrl);
      }

      successfulLogoutCallback();
    } catch (error) {
      handleErrorWithToast({
        id: `logoutUser-${Date.now().toString()}`,
        title: "Failed to logout. Please try again or reload the page",
        error,
      });
    }

    setIsLoggingOut(false);
    setLoadingSpinner(false);
  }, [
    coreApiClient.V3.SDB,
    handleErrorWithToast,
    loggedInUser,
    setLoadingSpinner,
    successfulLogoutCallback,
  ]);

  /** Action for when the user session has expired */
  const onConfirm = useCallback(() => {
    // Reload page so the login screen shows
    window.location.reload();
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, requestLogin, logout }}>
      {/* iframe where the Auth0 session invalidation will be silently carried out */}
      <iframe
        title="auth0-logout-iframe"
        style={{ display: "none" }}
        src={auth0LogoutUrl}
      />
      {!isLoggedIn && shouldShowUlm && <Ulm iFrameUrl={ulmUrl} />}
      {!isLoggedIn && !shouldShowUlm && <UserModule iFrameUrl={iFrameUrl} />}
      {isLoggedIn && !isLoggingOut && (
        <>
          {children}
          <FaroDialog
            open={isModalDialogOpen}
            title="Session expired"
            isSuccessMessage={true}
            onConfirm={onConfirm}
            confirmText="Login"
          >
            Your session has expired. Please login again to continue.
          </FaroDialog>
        </>
      )}
    </AuthContext.Provider>
  );
}
