import { runtimeConfig } from "@src/runtime-config";
import { SphereDashboardAPITypes } from "@stellar/api-logic";

/**
 * @returns The URL to the login page of the ULM
 *
 * Appends the following query params to the ULM URL:
 * - "client=sphereDashboard" to indicate the consumer of the ULM is the XG Dashboard
 * - "parent_url" where we passed the current location to redirect after a successful login
 * - If the current location is a deep link where the workspace is defined then the ULM should
 * only allow login in the current server instance. To achieve this we also append the "server"
 * query param with the current server identifier.
 */
export function getUlmUrl(): string {
  const url = new URL(`${runtimeConfig.urls.sphereEntryPageUrl}/login`);

  url.searchParams.append("client", "sphereDashboard");

  const parentUrl = window.location.href;
  url.searchParams.append("parent_url", parentUrl);

  const pathname = window.location.pathname;
  if (pathname !== "" && pathname !== "/") {
    const serverIdentifier = runtimeConfig.appEnv.includes("com")
      ? SphereDashboardAPITypes.EServerIdentifier.sphere2Us
      : SphereDashboardAPITypes.EServerIdentifier.sphere2Eu;

    url.searchParams.append("server", serverIdentifier);
  }

  return url.href;
}
