import { FaroTextButton } from "@components/common/faro-text-button";
import { useFilterQueryParams } from "@hooks/table-filters/use-filter-query-params";
import { Divider } from "@mui/material";

/** The clear all button and its functionality */
export function FilterToolbarClearAll(): JSX.Element | null {
  const { isAnyFilterApplied, clearAllFilters } = useFilterQueryParams();

  if (!isAnyFilterApplied) {
    return null;
  }

  return (
    <>
      {isAnyFilterApplied && <Divider orientation="vertical" />}
      <FaroTextButton
        onClick={clearAllFilters}
        tooltipText="Clear all filters"
        sx={{ fontWeight: 600 }}
      >
        Clear filters
      </FaroTextButton>
    </>
  );
}
