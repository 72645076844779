import { useFilterQueryParams } from "@hooks/table-filters/use-filter-query-params";
import {
  FilterTableEvents,
  TableType,
} from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { BaseFilterMapId } from "@components/common/faro-table/faro-table-filter/faro-table-filter-types";
import { Chip } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import CheckIcon from "@assets/icons/new/check_18px.svg?react";
import { HeaderForTableType } from "@components/common/faro-table/faro-table-types";

interface Props {
  /** The column in which filtering will apply to */
  filteredColumn: HeaderForTableType<TableType>;

  /** The label of the chip to show in filter bar */
  label: string;

  /** The items to show in the dropdown */
  item: BaseFilterMapId;

  /** The table in which the filter is applied to */
  filterTableType: FilterTableEvents;
}

/** A chip for just enabling/disabling a filter. */
export function SingleFilterChip({
  filteredColumn,
  label,
  item,
  filterTableType,
}: Props): JSX.Element {
  const { trackEvent } = useTrackEvent();
  const { getFilterIdsForColumn, updateFilterItem } = useFilterQueryParams();
  const activeFilterIds = getFilterIdsForColumn(filteredColumn);

  const isFilterActive = activeFilterIds.length > 0;

  function onChipClicked(): void {
    trackEvent({
      name: filterTableType,
      props: { filterBy: filteredColumn },
    });

    updateFilterItem({
      filteredColumn,
      filterId: Object.values(item)[0].id,
      shouldReplaceFilterInColumn: false,
    });
  }

  return (
    <Chip
      onClick={onChipClicked}
      label={label}
      variant="outlined"
      sx={{
        backgroundColor: sphereColors.pureWhite,
        color: sphereColors.gray800,
        fontSize: "12px",
        cursor: "pointer",
        border: `2px solid ${sphereColors.gray300}`,
      }}
      size={"small"}
      icon={
        isFilterActive ? (
          <CheckIcon
            style={{
              fontSize: "13px",
              color: sphereColors.blue500,
              fontWeight: "bold",
            }}
          />
        ) : undefined
      }
    />
  );
}
